import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useRafflesUser from '../../hooks/useRafflesUser';

function ActiveRafflesUser() {
    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ status: 0 }), []);
    const { raffles, loading, error } = useRafflesUser(queryParams);
    console.log(raffles);

    const handleRaffleClick = (id) => {
        navigate(`/status-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    // Función para truncar texto
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    if (loading) return <p>Cargando...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="grid-rifas-calientes">
            {raffles.length === 0 ? ( // Verificar si el arreglo de rifas está vacío
                <div className='message full-width'>No hay rifas activas en este momento.</div>
            ) : (
                raffles.map((raffleData) => (
                    <div
                        key={raffleData.raffle.id}
                        className="w-layout-vflex div-rifa-caliente container-hot-raffles"
                        onClick={() => handleRaffleClick(raffleData.raffle.id)}
                        style={{

                            backgroundImage: `url(${raffleData.raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    >
                        <div className="price-rifa-caliente">${raffleData.raffle.ticket_price}</div>
                        <div className="w-layout-vflex gap-24 full-width">
                            <div className="w-layout-vflex z-index-10">
                                <div className="font-20 font-light weight-500">{raffleData.raffle.title}</div>
                                <div className="font-small font-gray">{truncateText(raffleData.raffle.description, 100)}</div>
                            </div>
                            <div className="date-mis-rifas z-index-10">{new Date(raffleData.raffle.end_date).toLocaleDateString('es-ES', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                            })}</div>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default ActiveRafflesUser;