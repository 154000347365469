import React, { useState, useEffect } from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import CountdownTimer from '../components/CountdownTrimer';

const SelectBookRaffle = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();
    const { raffleDetail } = location.state || {};
    const raffleId = raffleDetail?.id;
    const navigate = useNavigate();
    const apiURL = process.env.REACT_APP_API_URL;
    const [unavailableListNumbers, setUnavailableListNumbers] = useState([]);
    const [numbers, setNumbers] = useState([]);
    const [filteredNumbers, setFilteredNumbers] = useState([]);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [limitReached, setLimitReached] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [notAvailableMessage, setNotAvailableMessage] = useState("");


    useEffect(() => {
        generateRandomNumbers();
        unavailableNumbers();
    }, []);

    /* Seleccionar numero random */
    const generateRandomNumbers = () => {
        const maxAttempts = 1000; // Límite para evitar loops infinitos
        const randomNumbers = new Set();
        let attempts = 0;

        while (randomNumbers.size < 10 && attempts < maxAttempts) {
            const randomNum = Math.floor(Math.random() * 10000) + 1; // Generar número entre 1 y 10000
            if (!unavailableListNumbers.includes(randomNum)) {
                randomNumbers.add(randomNum);
            }
            attempts++;
        }

        if (randomNumbers.size < 10) {
            console.warn('No fue posible generar 10 números únicos debido a la cantidad de números no disponibles.');
        }

        setNumbers(Array.from(randomNumbers));
    };


    /* Efecto de color cuando numero se selecciona */
    const handleSelectorChange = (event, number) => {
        if (event.target.checked) {
            setSelectedNumbers((prev) => [...prev, number]);
        } else {
            setSelectedNumbers((prev) => prev.filter((num) => num !== number));
        }
    };

    /* Generar numero de la suerte */
    const generateLuckyNumber = () => {
        // Filtra los números generados que aún no han sido seleccionados
        const availableNumbers = numbers.filter((num) => !selectedNumbers.includes(num));

        if (availableNumbers.length > 0) {
            // Selecciona aleatoriamente un número de los disponibles
            const luckyNumber = availableNumbers[Math.floor(Math.random() * availableNumbers.length)];
            // Agrega el número generado a la lista de seleccionados
            setSelectedNumbers((prev) => [...prev, luckyNumber]);
        } else {
            console.log('No hay números disponibles para generar el número de la suerte');
        }
    };

    /* Consultar numeros vendidos */
    const unavailableNumbers = async () => {
        try {
            const response = await axios.get(
                `${apiURL}raffles/unavailable_numbers/${raffleId}/`, {
                params: {
                    id: raffleId,
                },
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
            });

            setUnavailableListNumbers(response.data.unavailable_numbers);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    /* Buscador de numeros */
    const handleSearchChange = (event) => {
        const term = event.target.value.trim();
        setSearchTerm(term);
        const searchNumber = parseInt(term, 10);
        const isValidNumber = !isNaN(searchNumber) && term !== "";

        if (isValidNumber) {
            if (unavailableListNumbers.includes(searchNumber)) {
                // Si el número está en la lista de no disponibles
                setNotAvailableMessage(`El número ${searchNumber} no está disponible.`);
                setFilteredNumbers([]);
            } else if ((searchNumber >= 0) && (searchNumber <= 10000)) {
                // Si está disponible, agregarlo a los resultados
                setNotAvailableMessage("");
                setFilteredNumbers([searchNumber]);
            }
            else {
                setNotAvailableMessage('El número debe ser entre 0 y 10000');
                setFilteredNumbers([]);
            }
        } else if (term === "") {
            // Limpiar resultados si el término está vacío
            setNotAvailableMessage("");
            setFilteredNumbers([]);
        } else {
            // Si el término no es un número válido
            setNotAvailableMessage("");
            setFilteredNumbers([]);
        }
    };

    /* Efecto de deseleccion numero */
    const handleRemoveNumber = (number) => {
        setSelectedNumbers((prev) => prev.filter((num) => num !== number));
    };

    /* Enviar al backend los numeros seleccionados y pasar de pantalla */
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedNumbers.length === 0) return;

        setLoading(true);
        setError(null);

        console.log(selectedNumbers);
        console.log(raffleDetail.id);

        try {
            const response = await axios.post(
                `${apiURL}raffles/purchase/`,
                {
                    raffle: raffleDetail.id,
                    selected_numbers: selectedNumbers,
                    terms_accepted: true,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Token ${localStorage.getItem('token')}`,
                    },
                }
            );

            const raffle = raffleDetail.id;

            if (response.status === 200) {
                setLimitReached(false);
                const { /* clientSecret,  paymentIntentId, */ isFree } = response.data;

                if (isFree) {
                    navigate('/success', {
                        state: {
                            selectedNumbers,
                            raffle
                        }
                    });
                } else {
                    navigate('/details-pay-raffle', { state: { raffleDetail, selectedNumbers } });
                    /* localStorage.setItem('clientSecret', clientSecret);
                    localStorage.setItem('paymentIntentId', paymentIntentId); */
                }
            }
        } catch (error) {

            if (error.response?.status === 400) {
                const errorDetail = error.response.data?.error?.non_field_errors || [];
                console.log(errorDetail);
                if (errorDetail.includes("Has alcanzado el límite de compras para esta rifa.")) {
                    setError("Este perfil ha alcanzado el número máximo permitido de tickets para esta rifa. Ya ha seleccionado un total de <strong>10 números</strong>.");
                } else if (errorDetail.includes("Uno o más números ya han sido comprados.")){
                    setError("Uno o más números ya han sido comprados.");
                } else if (errorDetail.includes("Uno o más números seleccionados están fuera del rango permitido (00-1000).")){
                    setError("Uno o más números seleccionados están fuera del rango permitido (00-1000).");
                } else {
                    setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
                }
            } else {
                setError('Hubo un error al realizar la compra. Inténtalo de nuevo.');
            }
        } finally {
            setLoading(false);
        }
    };



    /* Cuando el tiempo se agota */
    const handleTimeUp = () => {
        alert('El tiempo ha expirado. Por favor, vuelve a generar los números.');
        navigate(`/info-raffle/${raffleDetail.id}`);
    };

    return (
        <div>
            <Navbar />
            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-150 w-container">
                    <div className="grid-select-numbers">
                        <div className="w-layout-vflex gap-32">
                            <h4>{raffleDetail?.title || 'Rifa'}</h4>
                            {error && (
                                            <div 
                                                className="error-message full-width error-big" 
                                                dangerouslySetInnerHTML={{ __html: error }}
                                            />
                                        )}
                            <div className="w-form full-width">
                                <form className="container-tabs-numbers" onSubmit={handleSubmit}>
                                    <input
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="fields w-input"
                                        placeholder="Introduzca texto a buscar"
                                        type="text"
                                    />
                                   
                                    {notAvailableMessage && <p style={{ color: "orange" }}>{notAvailableMessage}</p>}
                                    {filteredNumbers.length > 0 && (
                                    <div className="grid-numbers book full-width">
                                        
                                            {filteredNumbers.map((number, index) => (
                                                <label key={number} className="w-checkbox container-checkbox no-pointer full-width">
                                                    <input
                                                        type="checkbox"
                                                        id={number}
                                                        value={number}
                                                        onChange={(e) => handleSelectorChange(e, number)}
                                                        className="w-checkbox-input w-checkbox-input--inputType-custom check"
                                                        checked={selectedNumbers.includes(number)}
                                                    />
                                                    <span className="check">{number}</span>
                                                </label>
                                            ))}
                                        
                                    </div>
                                    )}
                                    <div className='w-layout-vflex'>
                                        <p className='font-small font-graydark'>Números aleatorios</p>
                                        <div className='grid-numbers raffle full-width'>
                                            {numbers.map((number, index) => (
                                                <label key={index} className="w-checkbox container-checkbox full-width">
                                                    <input
                                                        type="checkbox"
                                                        id={number}
                                                        value={number}
                                                        onChange={(e) => handleSelectorChange(e, number)}
                                                        className="w-checkbox-input w-checkbox-input--inputType-custom check"
                                                        checked={selectedNumbers.includes(number)}
                                                    />
                                                    <span className="check">{number}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                    <button
                                        className="btn-second w-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            generateRandomNumbers();
                                        }}>
                                        Refrescar
                                    </button>
                                    <button className="btn-second w-button full-width"
                                        onClick={(e) => {
                                            e.preventDefault(); 
                                            generateLuckyNumber();
                                        }}>
                                        Generar mi número de la suerte
                                        <img src="/icon-star-white.svg" loading="lazy" alt="" className="icon-star" />
                                    </button>
                                    <div className='w-layout-vflex'>
                                        <p className='font-small font-graydark'>Números seleccionados</p>
                                        <div className="grid-numbers raffle full-width">
                                            {selectedNumbers.map((number, index) => (
                                                <div key={index} className="w-checkbox container-checkbox full-width">
                                                    <span className="w-checkbox-input w-checkbox-input--inputType-custom no-pointer check w--checked">{number}</span>
                                                    <button
                                                        className="remove-button"
                                                        onClick={() => handleRemoveNumber(number)}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-layout-vflex duv-btn-buy-numbers">
                                        <button
                                            type="submit"
                                            className={`btn-second w-button ${selectedNumbers.length === 0 || limitReached ? 'disable' : ''}`}
                                            disabled={selectedNumbers.length === 0 || limitReached}
                                        >
                                            Comprar Rifa
                                        </button>
                                        <Link to={`/terms-and-conditions/${raffleDetail?.id}`} className="link">
                                            Ver términos y condiciones de esta rifa
                                        </Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="img-w-border">
                            <img src={`${raffleDetail?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`} loading="lazy" alt="" className="img-w-border" />
                            <div className="container-countdown">
                                <CountdownTimer onTimeUp={handleTimeUp} />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default SelectBookRaffle;
