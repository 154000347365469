import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import HotRafflesModelTwo from '../Raffles/HotRafflesModelTwo';

const ResultsPage = ({ results }) => {
    const navigate = useNavigate();
    const handleSearch = (category) => {
        navigate(`/results?search="${category}#resultado"`);
    };

    const handleRaffleClick = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };


    if (!results.length) {
        return <div className='full-width w-layout-vflex align-center gap-64'>
            <div className="w-layout-vflex align-center text-center mx_768px">
                <img src="/happy.svg" loading="lazy" alt="" />
                <h4 className='text-disable-error'>No se encontró resultado para esto, prueba con otras palabras o elige una categoría</h4>
            </div>
            <div className="grid-btns-refeal">
                <button className='_w-bg-yellow' onClick={() => handleSearch('Carros')}>Carros</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Motos')}>Motos</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Tecnología')}>Tecnología</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Ropa')}>Ropa</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Electrodomesticos')}>Electrodomesticos</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Infoproductos')}>Infoproductos</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Casa')}>Casa</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Arte')}>Arte</button>
                <button className='_w-bg-yellow' onClick={() => handleSearch('Viajes')}>Viajes</button>
            </div>
            <div className="w-layout-hflex">
                <div className="w-layout-vflex container-rifas-calientes">
                    <div className="w-layout-vflex div-text-rifas-calientes">
                        <h4>Rifas calientes</h4>
                        <p className="font-medium text-center">
                            ¿Quieres saber cuáles son las rifas que todos están jugando?
                            <br></br>
                            Descubre por qué estas rifas son las favoritas y únete a la emoción.
                        </p>
                    </div>
                    <HotRafflesModelTwo />
                </div>


            </div>
        </div>;
    }

    return (
        <div className='w-layout-vflex form-block-log align-center'>
            <div className="w-layout-hflex grid-rifas">
                {results.map((result) => (
                    <div key={result.id} className="w-layout-vflex div-punto-fuerte pointer" onClick={() => handleRaffleClick(result.id)}>
                        <div className="div-img-rifa">
                            <img
                                src={`${result?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`}
                                loading="lazy"
                                alt=""
                                className="img-factura"
                            />
                        </div>
                        <div className="font-20 weight-600">{result.title}</div>
                        <div className="price-listado-rifa">${result.ticket_price}</div>
                        <p>{result.description}</p>
                        <div className="btn-link w-inline-block">
                            <div>Ver más</div>
                            <img src="/arrow-right.svg" loading="lazy" alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </div>


    );
};

export default ResultsPage;