import React, { useState } from 'react';
import classNames from 'classnames';


const Collapsible = ({ id, title, content, isOpen, toggleCollapsible }) => {
  return (


    <div className="expandable-single" onClick={() => toggleCollapsible(id)}>
      <div className="expandable-top">
        <h4 className="font-18 weight-500">
          {title}
        </h4>
        <div className={classNames('arrow-expand-master', { 'open': isOpen, 'close': !isOpen })}>
          <img loading="lazy" src="/Icon.svg" alt="" />
        </div>
      </div>
      <div className={classNames('expandable-bottom', { 'open': isOpen, 'close': !isOpen })}>
        <div className="rich-text-block faq w-richtext">
          <p>
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};



const FAQSection = () => {

  const [openCollapsibles, setOpenCollapsibles] = useState({});

  const toggleCollapsible = (id) => {
    setOpenCollapsibles((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const collapsibles = [
    { id: 1, title: '¿Cuáles son los métodos de pago que aceptan?', content: 'Aceptamos pagos a través de Pago Móvil.' },
    { id: 2, title: '¿Quién regula las rifas y dónde salen los resultados?', content: 'Nuestras rifas están reguladas por el Instituto Oficial de Beneficencia Pública y Asistencia Social del Estado Táchira (Lotería del Táchira). Los resultados se publican en las plataformas oficiales de La Lotería del Táchira.' },
    { id: 3, title: '¿Dónde retiraría mi premio en caso de ser el ganador?', content: 'Los premios se retiran directamente en las instalaciones de nuestros aliados comerciales. En cada rifa, encontrarás información detallada sobre el comercio, incluyendo dirección y horarios de atención.' },
    { id: 4, title: '¿Cuánto tiempo tengo para retirar mi premio?', content: 'Una vez anunciado el número ganador, tendrás 72 horas para retirar tu premio en las tiendas físicas del aliado comercial correspondiente.' },
    { id: 5, title: '¿Se realizan devoluciones?', content: 'No, tras la compra de uno o más tickets en nuestra plataforma, no se aceptan devoluciones.' },
  ];

  return (
    <section>
      <div className="w-layout-blockcontainer container w-container">
        <div className="w-layout-vflex container-month-winners">
          <div className="w-layout-vflex div-text-rifas-calientes">
            <h4 className="text-center">Preguntas frecuentes</h4>
            <p className="font-medium text-center">
            ¿Tienes dudas? En nuestra sección de preguntas frecuentes encontrarás respuestas claras y concisas a las preguntas más comunes.
            </p>
          </div>
          <div className="w-layout-vflex container-faqs">
            {collapsibles.map((collapsible) => (
              <Collapsible
                key={collapsible.id}
                id={collapsible.id}
                title={collapsible.title}
                content={collapsible.content}
                isOpen={openCollapsibles[collapsible.id] || false}
                toggleCollapsible={toggleCollapsible}
              />
            ))}


          </div>
        </div>
      </div>

    </section>
  );
};

export default FAQSection;