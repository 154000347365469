import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useRaffles from '../../hooks/useRaffles';

function HotRafflesModelTwo() {
    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ is_hot: true }), []);
    const { raffles, loading, error } = useRaffles(queryParams);

    const handleRaffleClick = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    // Función para truncar texto
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="full-width">
            <div className="grid-rifas-calientes">
                {
                    raffles.length === 0 ? (
                        <div className='message full-width'>No hay rifas activas en este momento.</div>
                    ) : (
                        raffles.map((raffle) => (
                            <div key={raffle.id} className="w-layout-vflex div-punto-fuerte pointer" onClick={() => handleRaffleClick(raffle.id)} >
                                <div className="div-img-rifa">
                                    <img
                                        src={`${raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`}
                                        loading="lazy"
                                        alt=""
                                        className="img-factura"
                                    />
                                </div>
                                <div className="font-20 weight-600">{raffle.title}</div>
                                <div className="price-listado-rifa">${raffle.ticket_price}</div>
                                <p>{truncateText(raffle.description, 100)}</p>
                                <div className="btn-link w-inline-block">
                                    <div>Ver más</div>
                                    <img src="/arrow-right.svg" loading="lazy" alt="" />
                                </div>
                            </div>
                        )))
                }
            </div>
        </div>
    );
}

export default HotRafflesModelTwo;