import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import RaffleSlider from '../components/Raffles/HotRafflesSlider';
import HotRaffles from '../components/Raffles/HotRaffles';

const InfoRaffle = () => {
    const { id } = useParams();
    const [raffleDetail, setRaffleDetail] = useState(null);
    const [affiliate, setAffiliate] = useState('');
    const [raffleLoading, setRaffleLoading] = useState(true);
    const [affiliateLoading, setAffiliateLoading] = useState(false);
    const [raffleError, setRaffleError] = useState(null);
    const [affiliateError, setAffiliateError] = useState(null);
    const token = localStorage.getItem('token')
    const headers = token ? { Authorization: `Token ${token}` } : {};

    useEffect(() => {
        const fetchRaffleDetail = async () => {
            setRaffleLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}raffles/${id}/`, { headers });
                setRaffleDetail(response.data);
            } catch (err) {
                setRaffleError(err.message);
            } finally {
                setRaffleLoading(false);
            }
        };

        fetchRaffleDetail();
    }, [id]);


    useEffect(() => {
        if (raffleDetail?.affiliate) {
            const fetchRaffleAffiliate = async () => {
                setAffiliateLoading(true);
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}affiliates/${raffleDetail.affiliate}/`, { headers });
                    setAffiliate(response.data);
                } catch (err) {
                    setAffiliateError(err.message);
                } finally {
                    setAffiliateLoading(false);
                }
            };

            fetchRaffleAffiliate();
        }
    }, [raffleDetail]);

    if (raffleLoading || affiliateLoading) return <p>Loading...</p>;
    if (raffleError) return <p>Error al cargar la rifa: {raffleError}</p>;
    if (affiliateError) return <p>Error al cargar el afiliado: {affiliateError}</p>;



    return (
        <div>
            <Navbar />
            <section>
                <div className="w-layout-blockcontainer container w-container">
                    <div className="w-layout-vflex container-rifa-details">
                        <div className="font-yellow">Rifa</div>
                        <h3>{raffleDetail.title}</h3>
                        <div className="font-20 font-ray-dark">
                            {raffleDetail.description}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
                    {raffleDetail?.show_booklet_button ? (
                        <Link
                            to={`/select-book-raffle/${raffleDetail.id}`}
                            state={{ raffleDetail }}
                            className="banner-cta-rifa w-inline-block"
                            style={{
                                backgroundImage: `url(${raffleDetail?.images?.[0]?.image || '/imagen-no-disponible.jpg'})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className="content w-layout-vflex gap-24">
                                <div className="w-layout-vflex gap-8">
                                    <h1 className="font-40 font-light">{raffleDetail.title}</h1>
                                    <div className="font-light">Adquiere tu ticket ¡La Suerte te está esperando!</div>
                                </div>
                                <div className="w-layout-hflex align-center gap-24">
                                    <img src="/Group-37.svg" loading="lazy" alt="" />
                                    <div className="font-12 font-gray">
                                        ${raffleDetail.id === 14 || raffleDetail.id === 15
                                            ? raffleDetail.ticket_price
                                            : (raffleDetail.ticket_price * 4).toFixed(2)}
                                    </div>                                    <img src="/Group-39.svg" loading="lazy" alt="" />
                                    <div className="font-12 font-gray">{new Date(raffleDetail.start_date).toLocaleDateString()}</div>
                                </div>
                            </div>
                        </Link>
                    ) : (
                        <div
                            className="banner-cta-rifa w-inline-block"
                            style={{
                                backgroundImage: `url(${raffleDetail?.images?.[0]?.image || '/imagen-no-disponible.jpg'})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className="content w-layout-vflex gap-24">
                                <div className="w-layout-vflex gap-8">
                                    <h1 className="font-40 font-light">{raffleDetail.title}</h1>
                                    <div className="font-light">Este talonario es informativo.</div>
                                </div>
                                <div className="w-layout-hflex align-center gap-24">
                                    <img src="/Group-37.svg" loading="lazy" alt="" />
                                    <div className="font-12 font-gray">
                                        ${raffleDetail.id === 14 || raffleDetail.id === 15
                                            ? raffleDetail.ticket_price
                                            : (raffleDetail.ticket_price * 4).toFixed(2)}
                                    </div>                                    <img src="/Group-39.svg" loading="lazy" alt="" />
                                    <div className="font-12 font-gray">{new Date(raffleDetail.start_date).toLocaleDateString()}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>


            <section>
                <div className="w-layout-blockcontainer container w-container">
                    <div className="w-layout-vflex align-center gap-32">
                        {/* Botón Comprar Rifa visible si show_ticket_button es true y no es ni la rifa 14 ni 15 */}
                        {raffleDetail?.show_ticket_button && raffleDetail?.id !== 14 && raffleDetail?.id !== 15 && (
                            <Link
                                to={`/select-number-raffle/${raffleDetail.id}`}
                                state={{ raffleDetail }}
                                className="btn-second big w-button"
                            >
                                Comprar Rifa
                            </Link>
                        )}

                        {/* Botón Comprar Talonario visible si show_booklet_button es true */}
                        {raffleDetail?.show_booklet_button && (
                            <Link
                                to={`/select-book-raffle/${raffleDetail.id}`}
                                state={{ raffleDetail }}
                                className="btn-second big w-button"
                            >
                                Comprar Rifa
                            </Link>
                        )}

                        {/* Botón Obtener Número visible si el id de la rifa es 14 o 15 */}
                        {(raffleDetail?.id === 14 || raffleDetail?.id === 15) && (
                            <Link
                                to={`/select-number-raffle/${raffleDetail.id}`}
                                state={{ raffleDetail }}
                                className="btn-second big w-button"
                            >
                                Obtener Número
                            </Link>
                        )}
                    </div>
                </div>
            </section>






            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
                    {affiliate && (
                        <div className="w-layout-hflex container-perfil-aliado">
                            <div className="w-layout-hflex align-center gap-24 flex-vertical-mobile">
                                <img
                                    src={affiliate?.images?.[0]?.image || '/imagen-no-disponible.jpg'}
                                    loading="lazy"
                                    alt={affiliate?.images?.[0]?.image || "URL no disponible"}
                                    className="img-perfil-aliado"
                                />
                                <div className="w-layout-vflex gap-8 items-center-mobile">
                                    <div className="font-18 weight-600">{affiliate?.name}</div>
                                    <div className="font-graydark">Aliado de Riffapp</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {raffleDetail?.images?.length > 0 && (
                        <div className="grid-img-aliados">
                            {raffleDetail.images.map((imageObj) => (
                                <img
                                    key={imageObj.id}
                                    src={imageObj.image}
                                    loading="lazy"
                                    alt={`Imagen ${imageObj.id}`}
                                    className="img-aliado"
                                />
                            ))}
                        </div>
                    )}
                </div>
            </section>



            {raffleDetail?.images?.length > 0 && (
                <section className='pd-bottom-120'>
                    <div className="w-layout-blockcontainer container w-container">
                        <div className="w-layout-vflex align-center">
                            {raffleDetail?.show_ticket_button && raffleDetail?.id !== 14 && raffleDetail?.id !== 15 && (
                                <Link
                                    to={`/select-number-raffle/${raffleDetail.id}`}
                                    state={{ raffleDetail }}
                                    className="btn-second big w-button"
                                >
                                    Comprar Rifa
                                </Link>
                            )}

                            {/* Botón Comprar Talonario visible si show_booklet_button es true */}
                            {raffleDetail?.show_booklet_button && (
                                <Link
                                    to={`/select-book-raffle/${raffleDetail.id}`}
                                    state={{ raffleDetail }}
                                    className="btn-second big w-button"
                                >
                                    Comprar Rifa
                                </Link>
                            )}

                            {/* Botón Obtener Número visible si el id de la rifa es 14 o 15 */}
                            {(raffleDetail?.id === 14 || raffleDetail?.id === 15) && (
                                <Link
                                    to={`/select-number-raffle/${raffleDetail.id}`}
                                    state={{ raffleDetail }}
                                    className="btn-second big w-button"
                                >
                                    Obtener Número
                                </Link>
                            )}

                        </div>
                    </div>
                </section>
            )}

            {/*  <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-96 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Mayor oportunidad de ganar</div>
                            <h4>Otras rifas</h4>
                            <p className="font-medium text-center">
                                ¿Quieres saber cuáles son las rifas que todos están jugando?
                                <br></br>
                                Descubre por qué estas rifas son las favoritas y únete a la emoción.
                            </p>
                        </div>
                        <div className="full-width desktop">
                            <HotRaffles />
                        </div>

                        <div className="full-width mobile">
                            <RaffleSlider />
                        </div>
                    </div>
                </div>
            </section> */}
            <Footer />

        </div>
    );
};

export default InfoRaffle;
