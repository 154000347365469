import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';

const TermsAndConditions = () => {
    return (
        <div>
            <Navbar />

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Leer antes de usar</div>
                            <h4 className="text-center">Términos y Condiciones de RiffApp</h4>
                        </div>
                        <div className="w-layout-vflex">
                        <p className='letter-strong'>1. Introducción</p>
                    <p>
                        Estos términos regulan el uso de Riffapp, una plataforma para la compra de rifas en Venezuela. 
                        Al usar nuestro sitio web, aceptas estos términos en su totalidad.
                    </p>

                    <p className='letter-strong'>2. Registro y cuenta</p>
                    <ul>
                        <li>Los usuarios deben ser mayores de 18 años.</li>
                        <li>Es responsabilidad del usuario proporcionar información precisa y mantener la confidencialidad de su cuenta.</li>
                    </ul>

                    <p className='letter-strong'>3. Compra de rifas</p>
                    <ul>
                        <li>Todas las compras se realizan a través de pago móvil utilizando las cuentas bancarias proporcionadas.</li>
                        <li>No se aceptarán devoluciones una vez confirmada la compra.</li>
                        <li>Riffapp no se responsabiliza por errores en los datos proporcionados por el usuario al realizar un pago.</li>
                    </ul>

                    <p className='letter-strong'>4. Propiedad intelectual</p>
                    <p>
                        Todo el contenido de Riffapp (logos, textos, diseños) es propiedad exclusiva de la empresa y está protegido por leyes de derechos de autor.
                    </p>

                    <p className='letter-strong'>5. Límites de responsabilidad</p>
                    <ul>
                        <li>Riffapp no se hace responsable de interrupciones en el servicio debido a mantenimiento o problemas técnicos.</li>
                        <li>Riffapp no se hace responsable de pérdidas o daños causados por el uso de la plataforma.</li>
                    </ul>

                    <p className='letter-strong'>6. Ley aplicable</p>
                    <p>
                        Estos términos se rigen por las leyes de Venezuela.
                    </p>

                    <p className='letter-strong'>7. Contacto</p>
                    <p>
                        Para consultas sobre estos términos, contáctanos al correo <a href="mailto:info@riffapp.net">info@riffapp.net</a>.
                    </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default TermsAndConditions;
