import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';

const CookiePolicy = () => {
    return (
        <div>
            <Navbar />

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Uso de cookies</div>
                            <h4 className="text-center">Política de Cookies de RiffApp</h4>
                        </div>
                        <div className="w-layout-vflex">
                        <p className='letter-strong'>1. ¿Qué son las cookies?</p>
                    <p>
                        Las cookies son pequeños archivos de texto almacenados en tu dispositivo al visitar nuestra plataforma. Estas ayudan a mejorar tu experiencia de navegación.
                    </p>

                    <p className='letter-strong'>2. Tipos de cookies que utilizamos</p>
                    <ul>
                        <li>
                            <strong>Cookies esenciales:</strong> Necesarias para el funcionamiento de la página web.
                        </li>
                        <li>
                            <strong>Cookies analíticas:</strong> Nos permiten entender cómo interactúan los usuarios con la plataforma.
                        </li>
                        <li>
                            <strong>Cookies de preferencia:</strong> Guardan tus configuraciones como idioma o ubicación.
                        </li>
                    </ul>

                    <p className='letter-strong'>3. Gestión de cookies</p>
                    <p>
                        Puedes configurar tu navegador para aceptar, rechazar o eliminar cookies. Sin embargo, algunas funciones de la plataforma podrían no estar disponibles si desactivas las cookies.
                    </p>

                    <p className='letter-strong'>4. Cambios en esta política</p>
                    <p>
                        Podemos actualizar esta política de cookies y notificaremos cualquier cambio significativo en nuestro sitio web.
                    </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default CookiePolicy;
