import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';
import FAQSection from '../components/Faq';
import HotRaffles from '../components/Raffles/HotRaffles';
import jsonp from 'jsonp';
import RaffleSlider from '../components/Raffles/HotRafflesSlider';
import { Link as RouterLink } from 'react-router-dom';
import ActiveRaffles from '../components/Raffles/ActiveRaffles';
import ActiveRaffleSlider from '../components/Raffles/ActiveRafflesSlider';



function App() {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');

  const onSubmit = e => {
    e.preventDefault();
    const url = 'https://gmail.us10.list-manage.com/subscribe/post-json?u=49e693038e13a377ba91ce56e&amp;id=70296b0e98&amp;f_id=00f8cae5f0';
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      const { msg, result } = data
      setResponseMessage(msg);
    });
  };

  return (
    <div>
      <Navbar />

      <section>
        <div className="w-layout-vflex container-gift mobile">
          {/* <h2 className="title-gift">
              Rifa de un coche 0km <span className="font-yellow">Hyundai i20 2024</span>
            </h2> */}
          <img
            className='border-3'
            src="/dia-de-suerte-Riffapp.png"
            alt="Banner principal"
          />
          {/* <div className="img-valorado">
              <img src="/valorado.svg" alt="Valorado" className="img-circle-home" />
            </div> */}
        </div>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container pd-top-120 desktop">
          <div className="banner-home">
            <div className="w-layout-vflex">
              <div className="w-layout-vflex first-div-banner-home" id="newsletter">
                <h1 className="font-light">¡Suscribete para recibir promociones antes que todos!</h1>
                <div className=' w-layout-vflex maxw-768'>
                  <p className="font-20 font-light">
                    Recibe promociones exclusivas y sé el primero en enterarte de nuestras rifas más emocionantes. ¡No dejes pasar la oportunidad de ganar premios increíbles!
                  </p>
                  <div className="w-layout-vflex">
                    <form onSubmit={onSubmit} className="container-form-started">
                      <div className="w-layout-vflex gap-6">
                        <input
                          className="fields w-input"
                          type="email"
                          name="EMAIL"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="font-small font-light desktop">
                          Cuidamos tus datos con <Link to="/privacy-policy" className="link-light">política de privacidad</Link>.
                        </div>
                      </div>
                      <button type="submit" className="btn-dark btn-form w-button" value="Get started">Suscribirme</button>
                      <div className="font-small font-light mobile">
                        Cuidamos tus datos con <Link to="/" className="link-light">política de privacidad</Link>.
                      </div>
                    </form>
                    {responseMessage && (
                      <div className="message winner">
                        <div>¡Gracias por suscribirte y bienvenido a nuestra comunidad! 🎉</div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className="w-layout-vflex container-gift desktop">
            {/* <h2 className="title-gift">
              Rifa de un coche 0km <span className="font-yellow">Hyundai i20 2024</span>
            </h2> */}
            <img
              className='border-3'
              src="/dia-de-suerte-Riffapp.png"
              alt="Banner principal"
            />
            {/* <div className="img-valorado">
              <img src="/valorado.svg" alt="Valorado" className="img-circle-home" />
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container" id="raffles">
          <div className="w-layout-vflex container-rifas-calientes">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <div className="font-small _w-bg-yellow">Mayor oportunidad de ganar</div>
              <h4>Rifas activas</h4>
              <p className="font-medium text-center">
                Explora nuestra variedad de rifas activas y encuentra la que más se adapte a tus gustos. Cada rifa es una nueva chance de llevarte un premio increíble.
              </p>
            </div>
            <div className="full-width desktop">
              <ActiveRaffles />
            </div>

            <div className="full-width mobile">
              <ActiveRaffleSlider />
            </div>
          </div>
        </div>
      </section>


      <section className="bg-light-yellow">
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-vflex container-winners">
            <div className="container-tabs w-tabs">
              <div className="container-opc-tabs w-tab-menu">
                <div className={classNames('opc-tab w-inline-block w-tab-link', { 'w--current': activeTab === 'tab1' })}
                  onClick={() => handleTabClick('tab1')}>
                  <div>Ganadores</div>
                </div>
                <div className={classNames('opc-tab w-inline-block w-tab-link', { 'w--current': activeTab === 'tab2' })}
                  onClick={() => handleTabClick('tab2')}>
                  <div>Cómo funciona</div>
                </div>
              </div>
              <div className="w-tab-content">
                {activeTab === 'tab1' && <div data-w-tab="Tab 1" className="w-tab-pane">
                  <div className="w-layout-vflex align-center gap-24 text-center">
                    <h2>Ganadores</h2>
                    <p className="font-20 font-yellow text-center">
                      Pronto anunciaremos nuestro primer ganador
                    </p>
                    <p className="font-20 font-yellow text-center">
                      ¿Quieres ser tú?
                    </p>
                  </div>
                </div>
                }
                {activeTab === 'tab2' && <div data-w-tab="Tab 2" className="w-tab-pane">
                  <div className="w-layout-vflex align-center gap-24 text-center">
                    <h2>Cómo funciona</h2>
                    <p className="font-20 font-yellow text-center">
                      ¡Participar es TAN fácil como registrarte! <br></br>
                      1️⃣ Descarga nuestra app o entra en nuestra web. <br></br>
                      2️⃣ Regístrate con tus datos <br></br>
                      3️⃣ Adquiere tu ticket por tu rifa favorita y ¡Listo!
                    </p>
                    <p className="font-20 font-yellow text-center">¡Corre! Que la suerte ya está de tu lado. 🍀</p>
                  </div>
                </div>
                }
              </div>
            </div>
            <div className="w-layout-hflex container-buttons-center">
              {/* <Link to="/" className="btn-light w-inline-block">
                <img src="/play-circle.svg" loading="lazy" alt="" />
                <div>Demo</div>
              </Link> */}
              <Link to="/signup" className="btn-primary big w-button">
                ¡Regístrate!
              </Link>
            </div>
          </div>
        </div>
      </section>


      <section className="bg-line">
        <div className="w-layout-blockcontainer container w-container">
          <div
            className="video-winners w-background-video w-background-video-atom"
          >
            {/* <img
              className='border-3'
              src="/Banner-principal-2.png"
              alt="Banner principal"
            /> */}
            <video
              id="395057f7-a511-b471-9d37-c10e188fbaa3-video"
              autoPlay
              loop
              style={{ backgroundImage: 'url("/banner-dia-suerte.png")' }}
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source src="/videos/Animacion-de-logo-riffap.mp4" data-wf-ignore="true" />
            </video>

          </div>
        </div>
      </section>

      {/* Sección de estadisticas */}
      {/* <section>
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-vflex container-month-winners">
            <div className="w-layout-vflex div-text-rifas-calientes text-center">
              <h4>Ganadores de este mes</h4>
              <p className="font-medium text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
              </p>
            </div>
            <div className="w-layout-hflex div-numbers-month-winners">
              <div className="w-layout-vflex align-center">
                <h2 className="font-yellow">400+</h2>
                <div className="font-18 weight-600 text-center">
                  Projects completed
                </div>
              </div>
              <div className="w-layout-vflex align-center">
                <h2 className="font-yellow">600%</h2>
                <div className="font-18 weight-600 text-center">
                  Return on investment
                </div>
              </div>
              <div className="w-layout-vflex align-center">
                <h2 className="font-yellow">10k</h2>
                <div className="font-18 weight-600 text-center">
                  Global downloads
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-review-app">
            <div className="w-layout-vflex div-text-rifas-calientes">
              <h4 className="text-center">Con Riffapp, comprar rifas es más sencillo que nunca</h4>
              <p className="font-medium text-center">
                Selecciona tus números de la suerte o adquiere talonarios completos y participa en emocionantes sorteos.
              </p>
            </div>
            <img
              src="Content.png"
              loading="lazy"
              sizes="(max-width: 479px) 87vw, (max-width: 767px) 92vw, (max-width: 991px) 94vw, (max-width: 1280px) 95vw, 1216px"
              srcSet="/Content-p-500.png 500w, /Content-p-800.png 800w, /Content-p-1080.png 1080w, /Content.png 1216w"
              alt=""
            />
            {/* Tres puntos con información relevante */}
            {/* <div className="w-layout-hflex container-puntos-fuertes">
              <div className="w-layout-vflex div-punto-fuerte">
                <div className="font-20 weight-600">Punto fuerte 1</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <a href="#" className="btn-link w-inline-block">
                  <div>Learn more</div>
                  <img src="/arrow-right.svg" loading="lazy" alt="" />
                </a>
              </div>
              <div className="w-layout-vflex div-punto-fuerte">
                <div className="font-20 weight-600">Punto fuerte 2</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <a href="#" className="btn-link w-inline-block">
                  <div>Learn more</div>
                  <img src="/arrow-right.svg" loading="lazy" alt="" />
                </a>
              </div>
              <div className="w-layout-vflex div-punto-fuerte">
                <div className="font-20 weight-600">Punto fuerte 3</div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
                <a href="#" className="btn-link w-inline-block">
                  <div>Learn more</div>
                  <img src="/arrow-right.svg" loading="lazy" alt="" />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <FAQSection />

      {/* Contenedor de logos de proveedores */}
      <section>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container">
          <div className="w-layout-vflex container-proveedores">
            <h4 className="text-center">Aliados</h4>
            <p className="font-medium text-center">
              Nuestra red de aliados comerciales nos permite ofrecerte rifas con premios increíbles y de alta calidad.
            </p>
            <div className="w-layout-hflex div-logos">
              <img src="/Aliado-1.png" loading="lazy" alt="Logo" className='logo-aliado' />
              <img src="/Aliado-2.png" loading="lazy" alt="Logo" className='logo-aliado' />
              <img src="/Aliado-3.png" loading="lazy" alt="Logo" className='logo-aliado' />
              <img src="/Aliado-4.png" loading="lazy" alt="Logo" className='logo-aliado' />
              <img src="/Aliado-5.png" loading="lazy" alt="Logo" className='logo-aliado' />
              <img src="/Aliado-6.png" loading="lazy" alt="Logo" className='logo-aliado' />
            </div>
            <p className="text-small text-center">
              Si deseas formar parte de nuestra comunidad de aliados,  <RouterLink to="/help">haz clic aquí</RouterLink> para completar el formulario y unirte a Riffapp en nuestra misión de hacer la suerte más accesible para todos.
            </p>
          </div>
        </div>
        <div className="w-layout-blockcontainer container pd-bottom-96 w-container mobile">
          <div className="banner-home">
            <div className="w-layout-vflex">
              <div className="w-layout-vflex first-div-banner-home" id="newsletter">
                <h1 className="font-light">¡Suscribete para recibir promociones antes que todos!</h1>
                <div className=' w-layout-vflex maxw-768'>
                  <p className="font-20 font-light">
                    Recibe promociones exclusivas y sé el primero en enterarte de nuestras rifas más emocionantes. ¡No dejes pasar la oportunidad de ganar premios increíbles!
                  </p>
                  <div className="w-layout-vflex">
                    <form onSubmit={onSubmit} className="container-form-started">
                      <div className="w-layout-vflex gap-6">
                        <input
                          className="fields w-input"
                          type="email"
                          name="EMAIL"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <div className="font-small font-light desktop">
                          Cuidamos tus datos con <Link to="/privacy-policy" className="link-light">política de privacidad</Link>.
                        </div>
                      </div>
                      <button type="submit" className="btn-dark btn-form w-button" value="Get started">Suscribirme</button>
                      <div className="font-small font-light mobile">
                        Cuidamos tus datos con <Link to="/" className="link-light">política de privacidad</Link>.
                      </div>
                    </form>
                    {responseMessage && (
                      <div className="message winner">
                        <div>¡Gracias por suscribirte y bienvenido a nuestra comunidad! 🎉</div>
                      </div>
                    )}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Contenedor de logos de garantizado */}
      {/* <section className="bg-gray">
        <div className="w-layout-blockcontainer container w-container">
          <div className="w-layout-vflex container-garantizantes">
            <div className="font-20 font-ray-dark">Garantizado por</div>
            <div className="w-layout-hflex div-logos">
              <img src="/Boltshift-logo.svg" loading="lazy" alt="Boltshift Logo" />
              <img src="/Featherdev-logo.svg" loading="lazy" alt="Featherdev Logo" />
            </div>
          </div>
        </div>
      </section> */}
      <Footer />


    </div>
  );
}

export default App;

