import React, { useMemo } from 'react';
import useRaffles from '../../hooks/useRaffles';
import { useNavigate } from 'react-router-dom';

function ListRaffles() {
  const queryParams = useMemo(() => ({ status: 0 }), []);
  const navigate = useNavigate();
  const { raffles, loading, error } = useRaffles(queryParams);

  const handleRaffleClick = (id) => {
    navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
  };

  // Función para truncar texto
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="w-layout-hflex grid-rifas">
      {raffles.length === 0 ? (
        <div className='message full-width'>No hay rifas activas en este momento.</div>
      ) : (
        raffles.map((raffle) => {
          // Calcular el precio
          const displayPrice =
            raffle.id === 14 || raffle.id === 15
              ? raffle.ticket_price
              : raffle.ticket_price * 4;
  
          return (
            <div key={raffle.id} className="w-layout-vflex div-punto-fuerte">
              <div className="div-img-rifa">
                <img
                  src={`${raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'}`}
                  loading="lazy"
                  alt=""
                  className="img-factura"
                />
              </div>
              <div className="font-20 weight-600">{raffle.title}</div>
              <div className="price-listado-rifa">${displayPrice.toFixed(2)}</div>
              <p>{truncateText(raffle.description, 100)}</p>
              <div className="btn-link w-inline-block pointer" onClick={() => handleRaffleClick(raffle.id)}>
                <div>Ver más</div>
                <img src="/arrow-right.svg" loading="lazy" alt="" />
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default ListRaffles;