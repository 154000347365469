import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import useRaffles from '../../hooks/useRaffles';
import Slider from "react-slick";

const RaffleSlider = () => {

    const navigate = useNavigate(); // Hook para navegar a otra ruta
    const queryParams = useMemo(() => ({ is_hot: true }), []);
    const { raffles, loading, error } = useRaffles(queryParams);

    const handleRaffleClick = (id) => {
        navigate(`/info-raffle/${id}`); // Redirigir a la página de detalles con el ID de la rifa
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const settings = {
        dots: true, // Muestra puntos de navegación
        infinite: true, // Repite los elementos al llegar al final
        speed: 500,
        slidesToShow: 3, // Número de elementos visibles
        slidesToScroll: 1, // Elementos desplazados por clic
        responsive: [
            {
                breakpoint: 768, // Configuración para dispositivos móviles
                settings: {
                    infinite: raffles.length > 1, // Deshabilita infinito si hay 1 rifa
                slidesToShow: Math.min(1, raffles.length), // Muestra solo 1 rifa
                slidesToScroll: 1,
                },
            },
        ],
    };

    // Función para truncar texto
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <div className="raffle-slider">
            {
                raffles.length === 0 ? (
                    <div className='message full-width'>No hay rifas activas en este momento.</div>
                ) : (
            <Slider {...settings}>
                {raffles.map((raffle) => (
                    <div
                        key={raffle.id}
                        className="w-layout-vflex"
                        onClick={() => handleRaffleClick(raffle.id)} // Navegar a la página de detalles
                    >
                        <div className="w-layout-vflex div-rifa-caliente container-hot-raffles"
                        style={{
                            backgroundImage: `url(${raffle?.images?.[0]?.image || '/imagen-no-disponible.jpg'})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}>
                        <div className="price-rifa-caliente">${raffle.id === 14 || raffle.id === 15
                                            ? raffle.ticket_price
                                            : (raffle.ticket_price * 4).toFixed(2)}</div>
                        <div className="w-layout-vflex z-index-10">
                            <div className="font-20 font-light weight-500">{raffle.title}</div>
                            <div className="font-small font-gray">{truncateText(raffle.description, 100)}</div>
                        </div>
                    </div>
                    </div>
                ))}
            </Slider>
            )
        }
        </div>
    );
};

export default RaffleSlider;
