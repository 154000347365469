import React from 'react';

const ContactInfo = () => {
  return (
    <div className="w-layout-vflex container-left-bar pd-top-120">
      <div className="w-layout-vflex gap-32">
        <div className="w-layout-vflex gap-16">
          <h4 className="font-24 font-light">Aquí estamos para ti</h4>
          <p className="font-18 font-light">Contamos contigo para hacer de esta experiencia la mejor. Si tienes alguna pregunta, sugerencia o simplemente quieres saludarnos, ¡no dudes en contactarnos!</p>
        </div>

        <div className="w-layout-hflex gap-16">
          <img src="images/message-chat-circle.svg" alt="" loading="lazy" />
          <div className="w-layout-vflex gap-16">
            <h4 className="font-20 font-light">Chatea con nosotros</h4>
            <p className="font-light">
              No esperes más, solo un mensaje y estaremos en contacto{' '}
              <a href="mailto:info@riffapp.net" className="link-light">
              info@riffapp.net
              </a>
            </p>
          </div>
        </div>

        {/* <div className="w-layout-hflex gap-16">
          <img src="images/marker-pin-02.svg" alt="" loading="lazy" />
          <div className="w-layout-vflex gap-16">
            <h4 className="font-20 font-light">Oficinas</h4>
            <p className="font-light">Ven y cuéntanos en qué podemos ayudarte 100. Caracas, Venezuela 38002</p>
          </div>
        </div> */}

        <div className="w-layout-hflex gap-16">
          <img src="images/marker-pin-02.svg" alt="" loading="lazy" />
          <div className="w-layout-vflex gap-16">
            <h4 className="font-20 font-light">Teléfono</h4>
            <p className="font-light">Lun-Vie de 8am a 5pm.</p>
            <p className="font-light">
              <a href="tel:+584242558149" className="link-light">
              04242558149
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="w-layout-hflex container-rrss">
        <img src="images/icon-facebook-white.svg" alt="" loading="lazy" />
        <img src="images/icon-x-white.svg" alt="" loading="lazy" />
        <img src="images/icon-linkedin-white.svg" alt="" loading="lazy" />
        <img src="images/icon-youtube-white.svg" alt="" loading="lazy" />
      </div>
    </div>
  );
};

export default ContactInfo;
