import React from 'react';
import '../normalize.css'; // Estilos personalizados
import '../webflow.css'; // Estilos personalizados
import '../riffapp.webflow.css'; // Estilos personalizados
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer';

const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar />

            <section>
                <div className="w-layout-blockcontainer container pd-bottom-96 pd-top-120 w-container">
                    <div className="w-layout-vflex container-rifas-calientes">
                        <div className="w-layout-vflex div-text-rifas-calientes">
                            <div className="font-small _w-bg-yellow">Tu privacidad importa</div>
                            <h4 className="text-center">Políticas de Privacidad de RiffApp</h4>
                        </div>
                        <div className="w-layout-vflex">
                    <p className='letter-strong'>1. Introducción</p>
                    <p>
                        En Riffapp, nos comprometemos a proteger la privacidad y seguridad de los datos de nuestros usuarios. Este documento explica cómo recopilamos, utilizamos, almacenamos y protegemos tu información personal.
                    </p>

                <p className='letter-strong'>2. Información que recopilamos</p>
                    <ul>
                        <li>
                            <strong>Datos personales:</strong> Nombre completo, correo electrónico, número de teléfono, y datos bancarios necesarios para los pagos a través de pago móvil.
                        </li>
                        <li>
                            <strong>Datos automáticos:</strong> Dirección IP, tipo de navegador, dispositivo utilizado y páginas visitadas.
                        </li>
                        <li>
                            <strong>Cookies:</strong> Información sobre cómo interactúas con nuestra plataforma (ver <a href="/cookie-policy">Política de Cookies</a>).
                        </li>
                    </ul>

                    <p className='letter-strong'>3. Uso de la información</p>
                    <p>Usamos los datos recopilados para:</p>
                    <ul>
                        <li>Procesar y confirmar tus compras.</li>
                        <li>Ofrecer soporte al cliente.</li>
                        <li>Enviar notificaciones importantes sobre nuestras rifas y promoción de nuevos eventos.</li>
                        <li>Cumplir con las leyes y regulaciones aplicables en Venezuela.</li>
                    </ul>

                <p className='letter-strong'>4. Compartición de datos</p>
                    <p>
                        Riffapp no comparte tus datos personales con terceros, salvo en los siguientes casos:
                    </p>
                    <ul>
                        <li>Para cumplir con la ley o requerimientos legales.</li>
                        <li>Con proveedores de servicios que nos ayudan a operar la plataforma, bajo estrictos acuerdos de confidencialidad.</li>
                    </ul>

                <p className='letter-strong'>5. Seguridad de los datos</p>
                    <p>
                        Protegemos tus datos personales utilizando medidas de seguridad físicas, técnicas y administrativas. Sin embargo, no podemos garantizar la seguridad absoluta debido a la naturaleza del internet.
                    </p>

                <p className='letter-strong'>6. Tus derechos</p>
                    <ul>
                        <li>Acceso, rectificación o eliminación de tus datos.</li>
                        <li>Retiro del consentimiento para el tratamiento de tus datos.</li>
                    </ul>

                <p className='letter-strong'>7. Contacto</p>
                    <p>
                        Para cualquier consulta o solicitud relacionada con tus datos, contáctanos al correo: <a href="mailto:soporte@riffapp.com">soporte@riffapp.com</a>.
                    </p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
